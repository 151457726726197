@font-face {
  font-family: 'Emblem';
  src: url(../Fonts/emblem/Emblem.ttf);
  font-weight: 100;
}
@font-face {
  font-family: 'Emblem-italic';
  src: url(../Fonts/emblem/emblemitalic.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Emblem';
  src: url(../Fonts/emblem/emblemregular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: 'Emblem';
  src: url(../Fonts/emblem/emblemregular.ttf);
}
@font-face {
  font-family: 'Emblem-drongo';
  src: url(../Fonts/emblem/ji-drongo.ttf);
}
@font-face {
  font-family: 'Century-Gothic';
  src: url(../Fonts/century-gothic/CenturyGothic.ttf);
  font-weight: normal;
}
.container-home {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
}
.home-gallery {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
}
body {
  h1 {
    font-family: 'Emblem', 'sans-serif';
    font-weight: bold;
    color: #003f6f;
    font-size: 27px;
  }
  h2 {
  font-family: Century-gothic, 'sans-serif';
    color: #94c11c;
    font-weight: 400;
    font-size: 20px;
  }
  p {
    font-family: Century-gothic, 'sans-serif';
    line-height: 1.5;
  }
  .heading-green {
    color: #ffffff;
    background: #94c11c;
    padding: 15px;
    border-top-right-radius: 15px;
    margin: 0;
  }
}
.home-welcome {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
}
.home-welcome {
  .empty-div {
    width: 100%;
    height: 90px;
  }
}
.home-gallery {
  padding-bottom: 50px;
  .row-gallery {
    margin-bottom: 25px;
  }
  .zoom-parent {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;

    .gallery-content {
      position: absolute;
      top: 50px;
      left: 50px;
      z-index: 2;
      color: #ffffff;

      h4 {
        opacity: 0;
      }
    }
    &:hover {
      h4 {
        opacity: 1;
      }
    }
  }

  .bg-zoom-1 {
    background-image: url("images/Carousel/home-gallery-1.jpg");
    opacity: 1;

    &:hover {
      transform: scale(1.2);
      opacity:0.8;
    }
  }
  .bg-zoom-2 {
    background: url("images/Carousel/home-gallery-2.jpg");
    opacity: 1;
    &:hover {
      transform: scale(1.2);
      opacity:0.8;
    }
  }
  .bg-zoom-3 {
    background: url("images/Carousel/home-gallery-3.jpg");
    opacity: 1;
    &:hover {
      transform: scale(1.2);
      opacity:0.8;
    }
  }
  .bg-zoom-4 {
    background: url("images/Carousel/home-gallery-4.jpg");
    opacity: 1;
    &:hover {
      transform: scale(1.2);
      opacity:0.8;
    }
  }
  .zoom-gallery {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    transition: all .5s;
  }
}
.home-news .bg-zoom-n1 {
  background-image: url("images/home/home-img-1.jpg");
  opacity: 1; }
.home-news .bg-zoom-n1:hover {
  transform: scale(1.2);
  opacity: 0.9; }


.home-news .bg-zoom-n2 {
  background-image: url("images/home/home-img-2.jpg"); }
.home-news .bg-zoom-n2:hover {
  transform: scale(1.2);
  opacity: 0.9; }

.home-news .bg-zoom-n3 {
  background-image: url("images/home/home-img-3.jpg"); }
.home-news .bg-zoom-n3:hover {
  transform: scale(1.2);
  opacity: 0.9; }

.home-news .zoom-gallery {
  width: 100%;
  height: 350px;
  background-position: center;
  background-size: cover;
  transition: all .5s; }

.home-news .zoom-parent {
  width: 100%;
  height: 350px;
  overflow: hidden;
  position: relative; }
.home-news .zoom-parent .gallery-content {
  position: absolute;
  bottom: 15px;
  left: 30px;
  z-index: 2;
  color: #ffffff; }
.home-news .zoom-parent .gallery-content h5 {
  opacity: 0;
  color: #003f6f;
  font-weight: bold;
}
.home-news .zoom-parent:hover h5 {
  opacity: 1; }

.bg-zoom-n1.zoom-gallery {
  position:relative;
}

.bg-zoom-n1.zoom-gallery:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s linear;
}
.bg-zoom-n1.zoom-gallery:hover:before {
  background: #94c11c;
  opacity: 0.8;
}
.bg-zoom-n2.zoom-gallery {
  position:relative;
}

.bg-zoom-n2.zoom-gallery:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s linear;
}
.bg-zoom-n2.zoom-gallery:hover:before {
  background: #94c11c;
  opacity: 0.8;
}
.bg-zoom-n3.zoom-gallery {
  position:relative;
}

.bg-zoom-n3.zoom-gallery:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s linear;
}
.bg-zoom-n3.zoom-gallery:hover:before {
  background: #94c11c;
  opacity: 0.8;
}

.col-md-4 {
  margin-bottom: 25px;
}
.col-sm-6 {
  margin-bottom: 25px;
}
.col-sm-8 {
  margin-bottom: 25px;
}
.home-news {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.home-background {
  background-color: #f5f5f5;
}
.home-sponsors {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  padding-top: 50px;
  padding-bottom: 100px;
}

.sponsors-img-1 {
  background-image: url("images/sponsors/home-sponsor-1.jpg");
  background-size: cover;
  width: 110%;
  height: 150px;
}
.sponsors-img-2 {
    background-image: url("images/sponsors/home-sponsor-2.jpg");
    background-size: cover;
    width: 110%;
    height: 150px;
  }
.sponsors-img-3 {
  background-image: url("images/sponsors/home-sponsor-3.jpg");
  background-size: cover;
  width: 110%;
  height: 150px;
}
.sponsors-img-4 {
  background-image: url("images/sponsors/home-sponsor-4.jpg");
  background-size: cover;
  width: 110%;
  height: 150px;
}
.sponsors-img-5 {
  background-image: url("images/sponsors/home-sponsor-5.jpg");
  background-size: cover;
  width: 110%;
  height: 150px;
}
.footer-section {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  .row.footer-row {
    display: flex;
  }
  ul {
    list-style: none !important;
    font-family: Emblem, sans-serif !important;
    font-weight: 700 !important;
    color: #003f6f !important;
  }
  li {
    color: #919191 !important;
    font-weight: 100 !important;
    font-size: 13px !important;
    padding-left: 10px !important;
    &:hover {
    color: #003f6f !important;
  }

  }
}
.footer-div {
  border-top-color: #94c11c;
  border-top-width: 5px;
  border-top-style: solid;
  border-bottom-width: 30px;
  border-bottom-style: solid;
  border-bottom-color: #003f6f;
  background-image: url("images/sponsors/Footergrid.png");
  background-repeat: repeat-x;
}
.item.active {
  background-image: url("images/Carousel/Carousel-1.jpg");
  width: 100%;
  height: 100%;
}
.row.gallery-row img {
  width: 100%;
  height: 100%;
  border-color: #eee;
  border-width: 2px;
  border-style: solid;
  border-top-right-radius: 20px;
  background-repeat: no-repeat;
}
.row.gallery-row img:hover {
    color: red;
    border-color: #666666;
}
.carousel-inner {
  position: relative;
}
.home-slider-h {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
  z-index: 1;
  font-size: 60px;
  text-align: center;
  color: #ffffff;
  text-shadow: 1px 1px 15px rgba(0,0,0,.3);
  width: 80%;
}
.footer-copyr {
  margin-left: 35px;
  margin-top: 50px;
}
.image {
  width: 100%;
  height: 100%;
}
.header-box {
  width: 100%;
  height: 90px;
}
.image-responsive.logo-img {
  width: 200px;
}
.empty-box {
  height: 150px;
}
.navbar-default {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.green-bg {
  background: #94c11c;
}
.container-lid {
  max-width: 1120px;
  margin: 0 auto;
}
.container-lid-paragraph {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.white-heading {
  display: table-cell;
  padding: 15px;
  color: #ffffff;
}
.dossiers-heading {
  color: #ffffff;
  background: #94c11c;
  padding: 15px;
  border-top-right-radius: 15px;
  margin: 0;
}
.dossiers-paragraph {
  padding: 20px;
  background-color: #f5f5f5;
  height: 200px;
  background-repeat: no-repeat;
}
.bg-zoom-dossiers1.zoom-gallery {
  background-image: url("images/Dossiers/dossiers-1.jpeg");
}
.bg-zoom-dossiers2.zoom-gallery {
  background-image: url("images/Dossiers/dossiers-2.jpeg");
}
.bg-zoom-dossiers3.zoom-gallery {
  background-image: url("images/Dossiers/dossiers-3.jpg");
}
.bg-zoom-dossiers4.zoom-gallery {
  background-image: url("images/Dossiers/dossiers-4.jpg");
}
.bg-zoom-dossiers5.zoom-gallery {
  background-image: url("images/Dossiers/dossiers-5.jpg");
}
.voe-logo-1 {
  width: 250px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.voe-list {
  padding-left: 20px;
}
.voe p {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}
.voe ul li {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}
.voe-logo-2 {
  width: 160px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.voe-paragraph {
  padding-bottom: 20px;
  border-bottom: 8px solid #94c11c;
}
.voe-list.voe {
  padding-bottom: 20px;
  border-bottom: 8px solid #94c11c;
}

.heading-blue {
  color: #ffffff;
  background: #003f6f;
  padding: 15px;
  border-top-right-radius: 15px;
  margin: 0;
}
.p-padding {
  padding: 20px;
  height: 200px;
}
.voe h2 {
  font-size: 18px;
}
.home-color-box {
  background-color: #f5f5f5;
}
.row.voe-row {
  margin-top: 50px;
}
.white-heading.wetg {
  display: table-cell;
  padding: 15px;
}
.media-object {
  width: 235px;
  height: 235px;
}
.media-left.media-top {
  padding-right: 25px;
}
.nieuws-paragraph {
  font-size: 18px;
  color: #666;
}
.nieuws a {
  font-size: 14px;
  color: #003f6f;
  font-weight: 700;
}
.calendar {
  font-size: 12px;
  color: #c5c5c5;
}
.nieuws-paragraph.controleplan {
  padding-bottom: 20px;
  border-bottom: 8px solid #94c11c;
}
.controleplan-paragraph {
  margin-top: 20px;
}
.controleplan p {
  color: #919191;
}
.gevaloriseerde ul {
  padding-left: 15px;
}
.gevaloriseerde ul li {
  color: #919191;
  padding-left: 0;
}
.dossiers-heading-1 {
  padding-left: 15px;
  padding-bottom: 8px;
  color: #ffffff;
}

.wetg-container {
  max-width: 1120px;
  margin: 0 auto;
  padding-right: 15px;
}
.row.wetg-row {
  margin-top: 50px;
}
.container.wetg {
  border-top: 8px solid #94c11c;
  margin-bottom: 100px;
}
.wetgeving h2 {
  font-size: 18px;
}
.p-padding.wetg {
  font-size: 16px;
  color: #666;
}
.wetg-paragraph {
  font-size: 16px;
  color: #666;
}
.container.belg {
  border-bottom: 8px solid #94c11c;
  padding-bottom: 25px !important;
}
.container {
  padding: 0 !important;
}
.container.eurpoese {
  border-top: 8px solid #94c11c;
  margin-top: 45px;
}
.container.links {
  border-top: 8px solid #94c11c;
  margin-top: 45px;
}
.container.omzend {
  border-top: 8px solid #94c11c;
  margin-top: 45px;
}
@media screen and (max-width: 1024px) {
  .footer-section .row.footer-row {
    flex-direction: column !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 1024px) {
  .footer-section .row.home-footer  {
    text-align: center !important;
  }
}
.row.publicaties-row img {
  width: 100%;
  height: 100%;
  border: 1px solid #666666;
  background-repeat: no-repeat;
}
.publicaties-h2 {
  color: #003f6f;
  font-size: 17px;
  font-weight: 700;
}
.date {
  background: #c5c5c5;
  font-size: 18px;
  border-top-right-radius: 6px;
  height: 100%;
  line-height: 24px;
  padding: 10px;
  color: #ffffff;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}
.agenda-heading {
  color: #003f6f;
  font-size: 17px;
  text-decoration: none;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
}
html, body {
  overflow-x: hidden;
}
.col-md-2.publicaties {
  width: 20%;
}
@media screen and (max-width: 1024px){
  .col-md-2.publicaties {
    width: 99%;
  }
}
.b-w-h1 {
  font-style: normal;
  color: #003f6f;
  font-size: 17px;
  text-decoration: none;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
}
.b-w-h2 {
  font-size: 24px !important;
  line-height: 1.2;
  color: #94c11c;
  font-weight: 400;
}
.b-w-h4 {
  font-style: normal;
  color: #003f6f;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
}
.b-w-p {
  color: #000 !important;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 25px;
}
.b-w-p1 {
  color: #000 !important;
  font-size: 16px;
  line-height: 1.5;
  text-indent: 20px;
}
.b-w-p2 {
  text-indent: 20px;
}
.numbers {
  color: #94c11c;
}
.form-control.select {
  width: 40%;
}
.form-group.postcode {
  display: flex;
}
.form-control.postcode-input {
  margin-right: 20px;
}
@media screen and (max-width: 991px){
  .form-control.message {
    margin-bottom: 0;
  }
}
.col-md-6.message {
  padding-top: 72px;
}
.btn.btn-primary {
  border-width: 1px;
  border-style: solid;
  border-color: #919191;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  color: #222;
  border-top-right-radius: 15px;
  font-weight: 400;
  background-color: #fff;
  background-repeat: no-repeat;
}
.btn.btn-primary:hover {
  background: #94c11c;
}
.bemefa {
  color: #003f6f;
}
#myNavbar {
  display: flex !important;
  justify-content: center;
}
@media screen and (max-width: 1024px){
  .home-slider-h {
    font-size: 50px;
    top: 42%;
  }
}
@media screen and (max-width: 768px){
  .home-slider-h {
    font-size: 30px;
    top: 35%;
  }
}
@media screen and (max-width: 414px){
  .home-slider-h {
    font-size: 24px;
    top: 23%;
  }
}
@media screen and (max-width: 768px){
  .media.nieuws {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}
.publicaties-section {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-top: 50px;
}
.publicaties-section-2 {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 50px;
}
.lidmaatschap {
  max-width: 1120px;
  margin: 0 auto;
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.dropbtn {
  font-size: 16px;
  border: none;
  color: #919191 !important;

}
.dropbtn:hover {
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 768px){
  .dropdown-content {
  position: fixed !important;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
}

.dropdown-content a {
  color: #919191;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  list-style: none;
}
.dropdown-content a:hover {
 background: #f5f5f5 !important;
}
.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {color: #94c11c;}
a:hover {
  text-decoration: none !important;
  list-style: none !important;
  color: #919191 !important;
}
li a:hover {
  text-decoration: none !important;
  list-style: none !important;
  color: #919191 !important;
}
.dropdown {
  margin-right: 10px;
}
.navbar-collapse {
 padding-left: 0 !important;
}
@media screen and (max-width: 768px){
  .nav {
    padding-left: 40px !important;
  }
}
.contact-info {
  color: #333333;
}
.contact-info-wetg {
  color: #666666;
}
.contact-section {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-top: 50px;
}
.contact.section-2 {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 50px;
}
.wetgeving-section {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-top: 50px;
}
.wetgeving.section-2 {
  max-width: 1120px;
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 50px;
  border-top: 8px solid #94c11c;
}
.wetgeving-container {
  max-width: 1120px;
  margin: 0 auto;
}
.col-md-12.well {
  max-width: 1120px;
}
.form-control.postcode {
  width: 181px !important;
}
//@media screen and (max-width: 768px){
//  .form-control {
//    width: 389px !important;
//  }
//}
.navbar.navbar-default {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-responsive.logo {
  width: 200px;
}
@media screen and (max-width: 1024px){
  .img-responsive.gif {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 768px){
  .img-responsive.gif {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
.img-responsive.gif {
  width: 400px;
  margin-left: 140px;
  margin-right: 200px;
}
.img-responsive.login {
  width: 200px;
}
.navbar-toggle {
  background-color: white;
}
.icon-bar {
  background: black;
}
.carousel-control.left {
  background-image: none !important;
}
.carousel-control.right {
  background-image: none !important;
}
.header-bfa {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 768px){
  .nav.navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
  }
}
.header-list {
  position: absolute;
  right: -5px;
  top: -38px;
  z-index: 10;
  display: flex;
  list-style: none;
}

.header-li {
  padding: 8px;
  background-color: #003f6f;
  color: #fff !important;
  border-bottom-left-radius: 10px;
  margin-right: 5px;
  text-decoration: none;
}
.header-li.interessante {
  font-size: 14px;
}
.header-li.eurpoese {
  font-size: 14px;
}
.header-li.omzend {
  font-size: 14px;
}
.header-li.voe {
  font-size: 14px;
}
.header-li.bel {
  font-size: 14px;
}
.img-logo {
  //margin-top: 20px;
  font-size: 16px;
}
.header-li.fr {
  background-color: #e6f5fe;
  color: #94c11c;
}
.header-li.en {
  background-color: #e6f5fe;
  color: #94c11c;
}
.a-header {
  color: #919191 !important;
  font-size: 16px !important;
  font-weight: 100 !important;
}
.a-header:hover {
  color: #94c11c !important;
}
.inner-addon.left-addon {

}
.login-search {
  list-style: none;
  width: 197px;
  font-size: 25px;
  background: #94c11c;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.login-search1 {
  list-style: none;
  font-size: 25px;
  background: #e6f5fe;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.glyphicon.glyphicon-search {
  padding: 10px;
  color: #ffffff;
}
.fas.fa-key {
  padding: 10px;
  font-size: 15px;
  color: #ffffff !important;
}
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .glyphicon  { left:  0px;}
.right-addon .glyphicon { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }
.inner-addon.left-addon {
  margin-bottom: 5px;
}
.form-control.header {
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
  background-color: #94c11c !important;
}
.form-control.header::placeholder {
  color: #ffffff !important;
  background-color: #ffffff !important;
  padding: 4px;
}
.login-header {
  color: white;
}
@media screen and (max-width: 1024px) {
  .img-responsive.gif {
    display: none; } }
.div-header-right {
  position: relative;
}
.list-header1 {
  list-style: none;
  text-decoration: none;
  color: #ffffff !important;
  font-weight: 100 !important;
  padding: 6px;
}
.list-header2 {
  list-style: none;
  text-decoration: none;
  color: #94c11c !important;
  font-weight: 100 !important;
  padding: 6px;
}
.list-header3 {
  list-style: none;
  text-decoration: none;
  color: #94c11c !important;
  font-weight: 100 !important;
  padding: 6px;
}
.log-in {
  font-family: Century-gothic, 'sans-serif';
  color: black;
  font-weight: 500;
}
.col-md-2.index {
  width: 20%;
}
@media screen and (max-width: 1024px){
  .col-md-2.index {
    width: 100%;
  }
}
@media screen and (max-width: 414px){
  .header-bfa {
    margin-top: 10px;
  }
}
.agenda-anchor {
  font-weight: 100 !important;
}
@media screen and (max-width: 768px){
  .carousel-indicators {
    bottom: -5px !important;
  }
}
.meerweten {
  color: #003f6f;
  font-weight: 700;
}

